<div class="bg-light pb-3">
  <div
    class="banner-innerpage"
    style="background-image: url(assets/images/landingpage/banner-bg2.jpg)"
  >
    <div class="container">
      <!-- Row  -->
      <div class="row justify-content-center">
        <!-- Column -->
        <div class="col-lg-9 col-md-6 no-trans align-self-center text-center">
          <h1 class="title">Skills</h1>
        </div>
        <!-- Column -->
      </div>
    </div>
  </div>

  <div class="container card card-shadow mt-5 pb-3">
    <div class="row m-t-40 ml-3 justify-content-center">
      <!-- Column -->
      <div class="flex flex-col col-md-3 justify-content-between">
        <div class="no-trans mb-4 border-bottom">
          <div class="card" data-aos="flip-left" data-aos-duration="1200">
            <div class="row">
              <div class="no-trans">
                <h5 class="font-medium">
                  <i class="fa fa-code text-danger mr-2" aria-hidden="true"></i>
                  Technologies
                </h5>
                <p class="m-t-20">Java, Dotnet</p>
                <p class="m-t-20">REST, GraphQL</p>
                <p class="m-t-20">JavaScript, TypeScript</p>
                <p class="m-t-20">MySQL, PostgreSQL, MS SQL</p>
              </div>
            </div>
          </div>
        </div>

        <div class="no-trans mb-4">
          <div class="card" data-aos="flip-left" data-aos-duration="1200">
            <div class="row">
              <div class="no-trans">
                <h5 class="font-medium">
                  <i
                    class="fa fa-database text-danger mr-2"
                    aria-hidden="true"
                  ></i>
                  Services
                </h5>
                <p class="m-t-20">Apache Tomcat, Nginx</p>
                <p class="m-t-20">MongoDB, DynamoDB, Redis</p>
                <p class="m-t-20">Kafka, RabbitMQ</p>
                <p class="m-t-20">AWS, Docker, Kubernetes</p>
                <p class="m-t-20">MacOS, Windows, Linux</p>
                <p></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col col-md-3 justify-content-between">
        <div class="no-trans mb-4 border-bottom">
          <div class="card" data-aos="flip-left" data-aos-duration="1200">
            <div class="row">
              <div class="no-trans">
                <h5 class="font-medium">
                  <i class="fa fa-th-large text-danger mr-2"></i> Design
                  patterns
                </h5>
                <p class="m-t-20">MVC, Singleton,</p>
                <p class="m-t-20">Observer, Prototype</p>
                <p class="m-t-20">Factory Pattern,</p>
                <p class="m-t-20">Facade, Builder, Proxy</p>
              </div>
            </div>
          </div>
        </div>

        <div class="no-trans mb-4">
          <div class="card" data-aos="flip-left" data-aos-duration="1200">
            <div class="row">
              <div class="no-trans">
                <h5 class="font-medium">
                  <i
                    class="fa fa-star-o text-danger mr-2"
                    aria-hidden="true"
                  ></i
                  >Frameworks
                </h5>
                <p class="m-t-20">Spring Boot, ASP.NET Core</p>
                <p class="m-t-20">Mockito, JPA</p>
                <p class="m-t-20">Hibernate, EclipseLink</p>
                <p class="m-t-20">Angular, Node.js</p>
                <p class="m-t-20">Express</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
