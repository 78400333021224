import { Component } from '@angular/core';

@Component({
  selector: 'app-edu',
  templateUrl: './education.component.html'
})
export class EducationComponent {

  imagePath='../assets/images/innerpage/miu.png';
  imageBduPath='../assets/images/innerpage/bdu.png';

}
