<div class="topbar" id="top">
  <div class="header6">
    <div class="container po-relative">
      <ul class="mobile-menu" style="list-style-type: none">
        <li>
          <a href="#" class="btn btn-danger-gradiant font-14"
            ><i class="fa fa-bars mr-2" aria-hidden="true"></i>Menu
          </a>
          <ul
            *ngIf="isDropdownOpen"
            style="list-style-type: none"
            class="dropdown"
          >
            <li
              (click)="goto('home-page')"
              class="mobile-li"
              [routerLinkActive]="['active']"
            >
              <a> Home </a>
            </li>
            <li
              (click)="goto('about-page')"
              class="mobile-li"
              [routerLinkActive]="['active']"
            >
              <a>About</a>
            </li>
            <li
              (click)="goto('skill-page')"
              class="mobile-li"
              [routerLinkActive]="['active']"
            >
              <a>Skills</a>
            </li>
            <li
              (click)="goto('exp-page')"
              class="mobile-li"
              [routerLinkActive]="['active']"
            >
              <a>Experience</a>
            </li>
            <li
              (click)="goto('edu-page')"
              class="mobile-li"
              [routerLinkActive]="['active']"
            >
              <a>Education</a>
            </li>
            <li
              (click)="goto('blog-page')"
              class="mobile-li"
              [routerLinkActive]="['active']"
            >
              <a>Blogs</a>
            </li>
            <li
              (click)="goto('contact-page')"
              class="mobile-li"
              [routerLinkActive]="['active']"
            >
              <a>Contact Me </a>
            </li>
          </ul>
        </li>
      </ul>

      <nav class="navbar navbar-expand-lg h6-nav-bar">
        <div
          class="collapse navbar-collapse hover-dropdown font-14 m-auto"
          id="h6-info"
        >
          <ul class="navbar-nav m-auto align-items-center">
            <li class="nav-item" [routerLinkActive]="['active']">
              <a class="nav-link" (click)="goto('home-page')"> Home </a>
            </li>
            <li class="nav-item" [routerLinkActive]="['active']">
              <a class="nav-link" (click)="goto('about-page')">About</a>
            </li>
            <li class="nav-item" [routerLinkActive]="['active']">
              <a class="nav-link" (click)="goto('skill-page')">Skills</a>
            </li>
            <li class="nav-item" [routerLinkActive]="['active']">
              <a class="nav-link" (click)="goto('exp-page')">Experience</a>
            </li>
            <li class="nav-item" [routerLinkActive]="['active']">
              <a class="nav-link" (click)="goto('edu-page')">Education</a>
            </li>
            <li class="nav-item" [routerLinkActive]="['active']">
              <a class="nav-link" (click)="goto('blog-page')">Blogs</a>
            </li>

            <li class="nav-item">
              <a
                class="btn btn-danger-gradiant font-14"
                (click)="goto('contact-page')"
              >
                Contact Me
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
</div>

<div
  id="home-page"
  class="static-slider-head"
  style="background-image: url(assets/images/landingpage/banner-bg.jpg)"
>
  <div class="container">
    <!-- Row  -->
    <div class="row justify-content-center">
      <!-- Column -->
      <div class="col-lg-9 col-md-6 align-self-center text-center no-trans">
        <h1 class="title">Selamawit W</h1>
        <h5 class="subtitle op-5">
          I'm a Software Engineer at Lytx,Inc. | Full Stack Developer
        </h5>
      </div>
    </div>
  </div>
</div>

<!-- ============================================================== -->
<!-- About  -->
<!-- ============================================================== -->

<div id="about-page" class="bg-light feature1" style="padding: 45px 0px">
  <div class="container card card-shadow">
    <div class="no-trans align-self-center">
      <h2 class="title mb-5 mt-5" style="text-transform: uppercase">About</h2>
    </div>
    <div class="row wrap-feature-22 justify-content-center">
      <!-- Column -->
      <div
        class="col-lg-4"
        data-aos="flip-up"
        data-aos-duration="1200"
        style="margin-left: 64px"
      >
        <img
          [src]="imagePath"
          style="border-radius: 30px"
          class="img-responsive"
          alt=""
        />
      </div>
      <!-- Column -->
      <div class="col-lg-6 no-trans">
        <div class="text-box">
          <p>
            Experienced full-stack java developer with seven years of hands-on
            experience in developing enterprise applications. I have experience
            in software development using various latest technologies and
            languages like .Net, Java, Spring, Spring Boot, Hibernate,
            JavaScript, Angular, Express, MySQL, PostgreSQL, MongoDB, Amazon Web
            Services and more.
          </p>
          <p>
            I have demonstrated skills in problem-solving, Object-Oriented
            design and development, AOP, micro-services architecture, and modern
            web development.
          </p>
          <div class="row wrap-feature-22 m-t-20">
            <div>
              <div>
                <span class="ml-3 font-weight-bold">
                  <i class="fa fa-phone text-danger mr-2"></i> Phone </span
                >: 208-301-xxxx
              </div>
              <div>
                <span class="ml-3 font-weight-bold"
                  ><i
                    class="fa fa-envelope text-danger mr-2"
                    aria-hidden="true"
                  ></i
                  >Email </span
                >: selamawitwoldey@gmail.com
              </div>
              <div>
                <span class="ml-3 font-weight-bold"
                  ><i
                    class="fa fa-map-marker text-danger mr-3"
                    aria-hidden="true"
                  ></i
                  >Address </span
                >: San Diego, CA
              </div>
            </div>
            <div>
              <div>
                <span class="ml-3 font-weight-bold"
                  ><i
                    class="fa fa-briefcase text-danger mr-2"
                    aria-hidden="true"
                  ></i></span
                >7+ years of experience
              </div>
              <div>
                <span class="ml-3"
                  ><i
                    class="fa fa-graduation-cap text-danger mr-2"
                    aria-hidden="true"
                  ></i> </span
                >Msc in CS (ongoing)
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Column -->
    </div>

    <div class="row justify-content-center pb-5">
      <a href="https://www.linkedin.com/in/selamawitwoldeyohannes/">
        <i
          class="link fa fa-linkedin"
          aria-hidden="true"
          style="background: #0b66c2"
        ></i>
      </a>
      <a class="ml-3" href="https://github.com/SelamawitWY">
        <i
          class="link fa fa-github"
          aria-hidden="true"
          style="background: gray"
        ></i>
      </a>
      <a class="ml-3" href="mailto:selamawitwoldey@gmail.com">
        <i
          class="link fa fa-envelope"
          aria-hidden="true"
          style="background: red"
        ></i>
      </a>
      <a class="ml-3" href="https://medium.com/@selamawitwoldey">
        <i
          class="link fa fa-wordpress"
          aria-hidden="true"
          style="transform: rotate(180deg); background: black"
        ></i>
      </a>
    </div>
  </div>
</div>

<!-- ============================================================== -->
<!-- Others  -->
<!-- ============================================================== -->

<app-skill id="skill-page"></app-skill>
<app-exp id="exp-page"></app-exp>
<app-edu id="edu-page"></app-edu>
<app-blogs id="blog-page"></app-blogs>
<app-contact id="contact-page"></app-contact>

<div class="footer4 b-t mb-3">
  <div class="container text-center">
    <div class="mt-3 copyright">
      <div>@ 2024 - Selamawit Woldeyohannes</div>
      <div>Theme by <a href="https://www.wrappixel.com/">WrapPixel</a>.</div>
    </div>
  </div>
</div>
