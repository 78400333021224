<div class="bg-light pb-3">
  <div
    class="banner-innerpage"
    style="background-image: url(assets/images/landingpage/banner-bg2.jpg)"
  >
    <div class="container">
      <!-- Row  -->
      <div class="row justify-content-center">
        <!-- Column -->
        <div class="col-lg-9 col-md-6 no-trans align-self-center text-center">
          <h1 class="title">Education</h1>
        </div>
        <!-- Column -->
      </div>
    </div>
  </div>

  <div class="container smt-5 pb-3">
    <div class="pt-5 card card-shadow m-t-40 row justify-content-center">
      <div class="border-bottom p-4 col-md-6" style="margin: auto">
        <div class="row ju">
          <img
            [src]="imagePath"
            style="width: 72px; border-radius: 45px"
            alt=""
          />
          <h5 class="font-medium ml-3 mt-4">
            Maharishi International University, Fairfield, Iowa
          </h5>
        </div>
        <div class="m-t-20 m-b-20">Master of Science in Computer Science</div>
        <div class="m-b-20">
          (In progress via distance education; expected completion Dec 2024)
        </div>
      </div>

      <div class="p-4 col-md-6" style="margin: auto">
        <div class="row">
          <img
            [src]="imageBduPath"
            style="width: 72px; border-radius: 45px"
            alt=""
          />
          <h5 class="font-medium ml-3 mt-4">
            Bahir Dar University, Bahir Dar, Ethiopia
          </h5>
        </div>
        <div class="m-t-20 m-b-20">
          Bachelor of Science in Computer Science and Engineering
        </div>
        <div class="m-b-20">2012</div>
      </div>
    </div>
  </div>
</div>
