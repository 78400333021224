<div class="bg-light pb-5">
  <div
    class="banner-innerpage"
    style="background-image: url(assets/images/landingpage/banner-bg2.jpg)"
  >
    <div class="container">
      <!-- Row  -->
      <div class="row justify-content-center">
        <!-- Column -->
        <div class="col-lg-9 col-md-6 align-self-center text-center">
          <h1 class="title">BLOGS</h1>
        </div>
        <!-- Column -->
      </div>
    </div>
  </div>
  <!-- Row  -->
  <div class="row justify-content-center">
    <div class="container no-trans card card-shadow m-t-40">
      <div class="border-bottom m-5" *ngFor="let blogDetail of blogDetails">
        <div
          class="d-flex align-items-center text-uppercase font-13 font-medium"
        >
          <a routerLink="" class="link">By {{ blogDetail?.blogAuthor }}</a>
          <div class="ml-auto">
            <a routerLink="" class="link">{{ blogDetail?.blogDate }} </a>
          </div>
        </div>
        <h2 class="title font-light">
          {{ blogDetail?.heading }}
        </h2>

        <div class="row wrap-feature-22 justify-content-center">
          <!-- Column -->
          <div
            class="mt-5"
            data-aos="flip-up"
            data-aos-duration="1200"
            style="margin-left: 64px"
          >
            <img
              [src]="blogDetail.image"
              style="border-radius: 30px"
              class="img-responsive"
              alt=""
            />
          </div>
          <div class="no-trans">
            <p class="m-t-30 m-b-30">
              {{ blogDetail?.blogDetail }}
            </p>
          </div>
        </div>

        <div class="m-t-30 m-b-30">
          <button type="button" class="btn btn-danger-gradiant btn-rounded">
            <a
              href="{{ blogDetail?.blogLink }}"
              target="_blank"
              style="color: unset"
            >
              <i class="fa fa-info-circle mr-2"></i> Read more</a
            >
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
